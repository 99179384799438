import React from 'react';
import createReactClass from "create-react-class";

import LogoWhiteImg from "../assets/logo_white.png";
import LogoImg from "../assets/logo.png";
import EngineImg from "../assets/engine.png";
import CarImg from "../assets/car.png";
import Car1Img from "../assets/car_1.png";
import Car2Img from "../assets/car_2.png";
import PencilImg from "../assets/pencil.png";
import PointImg from "../assets/point.png";
import ClockImg from "../assets/clock.png";
import PhoneImg from "../assets/phone.png";
import EmailImg from "../assets/email.png";

import './Header.css';

const Header = createReactClass({

  getFormData(formData, form) {
    const data = new FormData(formData);
    const obj = {};
    for (const key of data.keys()) {
      const value = data.get(key);
      if (value && value !== '') {
        obj[key] = data.get(key);
      }
    }
    obj.type = form;
    return obj;
  },

  handleSubmitNeedPart(event) {
    event.preventDefault();
    const data = this.getFormData(event.target, "needPart");
    const from = data.email;
    const email = {
      from,
      "subject": "Dotaz na díl",
      "text": `
      jméno: ${data.fullName},
      model: ${data.model},
      rok: ${data.year}, 
      díl: ${data.part},
      poznámka: ${data.note},
      motor: ${data.engine},
      mobil: ${data.mobile}`
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(email)
    };
    fetch("/sendEmail", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.sent) {
            window.alert("E-mail odeslán");
            document.getElementById("needPartForm").reset();
          }
        },
        (error) => {
          window.alert("Nastala chyba při odesálání emailu, využite prosím kontatu na mobil.");
        }
      )
      .catch(error => {
        window.alert("Nastala chyba při odesálání emailu, využite prosím kontatu na mobil.");
      });
  },

  handleSubmitOfferCar(event) {
    event.preventDefault();
    const data = this.getFormData(event.target, "offerCar");
    const from = data.email;
    const email = {
      from,
      "subject": "Nabídka auta",
      "text": `
      jméno: ${data.fullName},
      model: ${data.model},
      rok: ${data.year}, 
      nájezd: ${data.km},
      motor: ${data.engine},
      palivo: ${data.fuel},
      mobil: ${data.mobile}`
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(email)
    };
    fetch("/sendEmail", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.sent) {
            window.alert("E-mail odeslán");
            document.getElementById("offerCarForm").reset();
          }
        },
        (error) => {
          window.alert("Nastala chyba při odesálání emailu, využite prosím kontatu na mobil.");
        }
      )
      .catch(error => {
        window.alert("Nastala chyba při odesálání emailu, využite prosím kontatu na mobil.");
      });
  },

  render() {
    return (
      <div className="header">
        <header className="block">
          <img src={LogoWhiteImg} alt="" />
          <div className="contacts">
            <a href="tel:777-911-785">777 191 785</a>
            <span>NEBO</span>
            <a href="tel:777-580-877">777 580 877</a>
          </div>
        </header>

        <div className="block info">

          <div className="half">
            <div className="background">
              <img src={EngineImg} alt="engine" />
              <div className="description">
                <p><span>PRODEJ</span> <span>NÁHRADNÍCH</span> <span>DÍLŮ</span></p>
                <a href="#needPart"> SHÁNÍM DÍL </a>
              </div>
            </div>
          </div>

          <div className="half">
            <div className="background">
              <img src={CarImg} alt="car" />
              <div className="description">
                <p><span>VÝKUP</span> <span>OJETÝCH</span> <span>VOZŮ</span></p>
                <a href="#offerCar"> NABÍZÍM VŮZ </a>
              </div>
            </div>
          </div>

        </div>

        <div className="block info2" id="needPart">
          <div className="container left">
            <div className="flex">
              <img src={EngineImg} alt="engine" />
              <div className="description">
                <p><span>PRODEJ</span><span>NÁHRADNÍCH</span><span>DÍLŮ</span></p>
              </div>
            </div>
            <p>Naše firma se zabývá prodejem a výkupem náhradních dílů a vozů značky Renault.</p>
          </div>

          <div className="container">
            <form className="login-form" id="needPartForm" onSubmit={this.handleSubmitNeedPart}>
              <div className="form-header">
                <img src={PencilImg} alt="engine" />
                <h1><p>Náhradní</p>&nbsp;<p>díly</p>&nbsp;<p>RENAULT</p></h1>
              </div>
              <div className="widget">
                <div className="widget-header">
                  <p className="header-content">OSOBNÍ ÚDAJE</p>
                </div>
                <div className="widget-content">
                  <div className="form-input-material">
                    <input type="text" name="fullName" id="fullName" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="fullName">Celé jméno</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="mobile" id="mobile" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="mobile">Telefon</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="email" id="email" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="email">E-mail</label>
                  </div>
                </div>
              </div>
              <div className="widget">
                <div className="widget-header">
                  <p className="header-content">ÚDAJE O VOZU</p>
                </div>
                <div className="widget-content">
                  <div className="form-input-material">
                    <input type="text" name="model" id="model" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="model">Model</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="year" id="year" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="year">Rok</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="engine" id="engine" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="engine">Motor</label>
                  </div>
                  <div className="form-input-material row">
                    <input type="text" name="part" id="part" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="part">Poptávaný náhradní díl</label>
                  </div>
                  <div className="form-input-material row">
                    <input type="text" name="note" id="note" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="note">Poznámka</label>
                  </div>
                </div>
              </div>
              <div className="widget last">
                <div className="widget-header" />
                <div className="widget-content">
                  <div>
                    <button type="submit" className="submit-button">ODESLAT</button>
                    <p>Odesláním formuláře souhlasíte se zpracováním osobních údajů</p>
                  </div>
                  <div>
                    <img src={Car1Img} alt="engine" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="block info3">
          <div className="container" />
          <div className="container left">
            <div className="flex">
              <div className="description">
                <p><span>VELKÝ</span>&nbsp;<span>VÝBĚR</span><br /><span>NÁHRADNÍCH</span>&nbsp;<span>DÍLŮ</span></p>
              </div>
            </div>
            <div>
              <button className="submit-button">ODESLAT</button>
              <span>NEBO</span>
              <button type="submit" className="submit-button">ODESLAT</button>
            </div>
          </div>
        </div>

        <div className="block info4">
          <div className="container left">
            <div className="flex">
              <img src={CarImg} alt="car" />
              <div className="description">
                <p><span>VÝKUP</span><span>OJETÝCH</span><span>VOZŮ</span></p>
              </div>
            </div>
            <p>Vykupujeme veškerá osobní vozidla značky Renault. Na výslednou výkupní cenu má vliv mnoho proměnných,
              jako třeba počet ujetých kiometrů, původ a výbava vozu, předchozí nehody, celkový stav atd. </p>
          </div>

          <div className="container" id="offerCar">
            <form className="login-form" onSubmit={this.handleSubmitOfferCar} id="offerCarForm">
              <div className="form-header">
                <img src={PencilImg} alt="engine" />
                <h1><p>Výkup</p>&nbsp;<p>vozidla</p>&nbsp;<p>RENAULT</p></h1>
              </div>
              <div className="widget">
                <div className="widget-header">
                  <p className="header-content">OSOBNÍ ÚDAJE</p>
                </div>
                <div className="widget-content">
                  <div className="form-input-material">
                    <input type="text" name="fullName" id="fullName" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="fullName">Celé jméno</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="mobile" id="mobile" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="mobile">Telefon</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="email" id="email" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="email">E-mail</label>
                  </div>
                </div>
              </div>
              <div className="widget">
                <div className="widget-header">
                  <p className="header-content">ÚDAJE O VOZU</p>
                </div>
                <div className="widget-content">
                  <div className="form-input-material">
                    <input type="text" name="model" id="model" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="model">Model</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="year" id="year" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="year">Rok</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="engine" id="engine" placeholder=" " autoComplete="off"
                           className="form-control-material" required />
                    <label htmlFor="engine">Motor</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="fuel" id="fuel" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="fuel">Palivo</label>
                  </div>
                  <div className="form-input-material">
                    <input type="text" name="km" id="km" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="km">Počet najetých km</label>
                  </div>
                  <div className="form-input-material row">
                    <input type="text" name="note" id="note" placeholder=" " autoComplete="off"
                           className="form-control-material" />
                    <label htmlFor="note">Poznámka</label>
                  </div>
                </div>
              </div>
              <div className="widget last">
                <div className="widget-header" />
                <div className="widget-content">
                  <div>
                    <button type="submit" className="submit-button" onClick={this.sendEmail}>ODESLAT</button>
                    <p>Odesláním formuláře souhlasíte se zpracováním osobních údajů</p>
                  </div>
                  <div>
                    <img src={Car1Img} alt="engine" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="block info5">
          <div className="container left">
            <div className="flex">
              <img src={Car2Img} alt="car" />
              <div className="description">
                <p><span>PODPOROVANÉ</span><span>VOZY</span></p>
              </div>
            </div>
            <p>Podporujeme následné modely vozidel a to jak na <b>výkup vozidel,</b> tak na <b>prodej náhradních
              dílů</b></p>
          </div>
          <div className="container">
            <div className="supported-model">
              <label>Megane</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Scenic</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Clio</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Twingo</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Laguna</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Espace</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Fluence</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Master</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Vel Satis</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Kangoo</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Modus</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Koleos</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Logan</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Logan MCV</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Duster</label>
              <label>1994 - 2018</label>
            </div>
            <div className="supported-model">
              <label>Fluence</label>
              <label>1994 - 2018</label>
            </div>
          </div>
        </div>

        <footer id="footer">
          <div className="header-row">
            <div className="footer-logo">
              <img src={LogoImg} width={128} alt="logo" />
            </div>
            <div className="footer-info">
              <div>
                <div className="footer-heading">
                  <img src={PointImg} alt="point" />
                  <h3>KONTAKTNÍ ÚDAJE</h3>
                </div>
                <p><b>J&J AUTOPRODEJ S.R.O.</b></p>
                <p><b>IČ:</b> 26003961</p>
                <p>Podlesí 101</p>
                <p>Holice 534 01</p>
              </div>
              <div>
                <p><img src={PhoneImg} alt="phone" /> 777 191 785</p>
                <p><img src={PhoneImg} alt="phone" /> 777 580 877</p>
                <p><img src={EmailImg} alt="email" /> autoprodej.holice@seznam.cz</p>
              </div>
              <div>
                <div className="footer-heading">
                  <img src={ClockImg} alt="clock" />
                  <h3>OTEVÍRACÍ DOBA</h3>
                </div>
                <p><b>PONDĚLÍ</b> až <b>PÁTEK</b></p>
                <p>8:00 - 11:00 12:00 - 16:00</p>
                <p><b>SOBOTA až NEDĚLE</b></p>
                <p>Zavřeno</p>
              </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.9289060509846!2d15.971717016051556!3d50.087617921322504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dd03dca82eb11%3A0xd6184343995250cc!2sJ%20%26%20J%20Autoprodej%20s.%20r.%20o.!5e0!3m2!1scs!2scz!4v1604969125482!5m2!1scs!2scz"
            width="100%" height="600" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"
            tabIndex="0" />
        </footer>

      </div>
    );
  }
});

export default Header;
